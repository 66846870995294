<script>
export default {
	lang: 'books',
	inject: ['editor'],
	props: {
		disableOnInit: Boolean,
	},
	data() {
		return {
			carouselItems: null,
			currentIndex: 0,
		}
	},
	computed: {
		e() {
			return this.editor()
		},
	},
	methods: {
		async setup() {
			let items = []
			let addItems = (imgsArr, wrapperClass = '') => {
				imgsArr.forEach((src) => items.push({ src, wrapperClass }))
			}
			addItems(this.e.bookImgs.t, 'front-cover')
			addItems(this.e.bookImgs.i)
			addItems(this.e.bookImgs.t, 'back-cover')
			this.carouselItems = items

			await new Promise(this.$nextTick)
			this.$set(this.e, 'carouselBook', this.$refs.carousel)
			this.$set(this.e, 'carouselBookItemsLength', this.carouselItems.length)

			this.$emit('ready')
		},
	},
	watch: {
		currentIndex() {
			this.$set(this.e, 'carouselBookCurrentIndex', this.currentIndex)
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.setup()
		})
	},
}
</script>

<template>
	<div v-if="carouselItems">
		<Carousel v-model="currentIndex" :items="carouselItems" :options="{
		arrows: false,
		itemWrapperClass: 'd-flex justify-center',
		panelsPerView: 1.1,
	}" :flicking-options="{
		align: 'center',
		bound: false,
		disableOnInit,
	}" ref="carousel">
			<template #panel="{ item }">
				<BookPageWrapper :class="item.wrapperClass"
					:style="{ width: `${e.pageSize.w}px`, height: `${e.pageSize.h}px` }">
					<img :src="item.src" />
				</BookPageWrapper>
			</template>
			<!-- <template #left-arrow="{ canMovePrev, movePrev }">
				<Button
					v-if="canMovePrev"
					@click="movePrev"
					class="arrow"
					:style="{ left: '8px' }"
					text
					color="darkblue"
				>
					<v-icon class="arrow-icon" small>mdi-chevron-left</v-icon> {{ 'ANTERIOR' | lang }}
				</Button>
			</template>
			<template #right-arrow="{ canMoveNext, moveNext }">
				<Button
					v-if="canMoveNext"
					@click="moveNext"
					class="arrow"
					:style="{ right: '8px' }"
					text
					color="darkblue"
				>
					{{ 'SIGUIENTE' | lang }} <v-icon class="arrow-icon" small>mdi-chevron-right</v-icon>
				</Button>
			</template> -->
			<template #free-slot="{ currentIndex }">
				<div class="page-count">
					<b>{{ currentIndex + 1 }}</b> / <b>{{ carouselItems.length }}</b>
				</div>
			</template>
		</Carousel>
	</div>
</template>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.page-wrapper {
	position: relative;
	margin: 0 6px;
	border-radius: 12px;
}

.arrow {
	top: 97%;
	width: unset;
}

.page-count {
	top: 101%;
	position: absolute;
	width: 100%;
	text-align: right;
	padding-right: 20px;
}

@media #{map-get($display-breakpoints, 'sm-only')} {
	.arrow {
		top: 100%;
	}
}
</style>
