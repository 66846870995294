<script>
import { PageFlip } from 'page-flip'
import imgBookPreCoverTxt from '../assets/book-pre-cover-txt.png'
import imgBookPreCoverTxt_pt from '../assets/book-pre-cover-txt--pt.png'
import imgBookPreCoverTxt_en from '../assets/book-pre-cover-txt--en.png'
import { langIso } from '@/__shared/lang/lib_lang'

export default {
	lang: 'books',
	inject: ['editor'],
	data() {
		return {
			pageFlip: null,
			ready: false,
			currentPage: null,
			totalPages: null,
			showCounter: false,
		}
	},
	computed: {
		e() {
			return this.editor()
		},
		imgBookPreCoverTxt() {
			switch (langIso) {
				case 'pt':
					return imgBookPreCoverTxt_pt
				case 'en':
					return imgBookPreCoverTxt_en
				default:
					return imgBookPreCoverTxt
			}
		},
	},
	methods: {
		async setup() {
			let promises = [this.imgBookPreCoverTxt, this.e.config.preCoverImg, ...this.e.bookImgs.t].map(
				(src) => {
					return new Promise((resolve, reject) => {
						let img = new Image()
						img.onload = resolve
						img.src = src
					})
				}
			)

			promises.push(
				new Promise((resolve) => {
					this.$nextTick(() => {
						setTimeout(() => {
							this.pageFlip = new PageFlip(document.getElementById('pageflip'), {
								width: this.e.pageSize.w,
								height: this.e.pageSize.h,
								size: 'fixed',
								maxShadowOpacity: 0.5,
								mobileScrollSupport: false,
							})
							this.pageFlip.on('init', async () => {
								this.$nextTick(resolve)
							})
							this.pageFlip.loadFromHTML(document.querySelectorAll('.page-wrapper'))
						}, 1000)
					})
				})
			)

			await Promise.all(promises)
			this.currentPage = 0
			let pageCount = this.pageFlip.getPageCount()
			this.totalPages = pageCount - 6
			this.pageFlip.on('flip', async () => {
				let index = this.pageFlip.getCurrentPageIndex()
				this.showCounter = index < pageCount - 2
				this.currentPage = Math.min(this.totalPages, Math.max(0, index - 1))
			})
			this.ready = true
			this.showCounter = true
			this.$set(this.e, 'pageFlip', this.pageFlip)
			this.$emit('ready')
		},

		startFlip() {
			this.pageFlip.flipNext()
		},
		backToStartFlip() {
			this.pageFlip.flip(0)
		},
		normalizeSrc(src) {
			return src.replace(/\\+/g, '/')
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.setup()
		})
	},
}
</script>

<template>
	<div class="book-container" :style="{ width: `${e.pageSize.w * 2}px` }">
		<div class="book-parent" :class="{ 'book-parent--ready': ready, 'book-parent--unready': !ready }"
			:style="{ width: `${e.pageSize.w * 2}px`, height: `${e.pageSize.h}px` }">
			<div id="pageflip" class="book">
				<BookPageWrapper class="pre-cover">
					<Media :src="e.config.preCoverImg" cover @click="startFlip" eager class="w100 h100">
						<div class="w100 h100">
							<div class="w100 h100 d-flex align-center justify-center cover-txt-anim-container">
								<Media class="cover-txt-anim" :src="imgBookPreCoverTxt" contain width="212" max-width="212"
									aspect-ratio="1" eager />
							</div>
							<Button text color="white" small class="cover-cta font-weight-bold">
								{{ 'CLICK AQUÍ' | lang }}
							</Button>
						</div>
					</Media>
				</BookPageWrapper>
				<BookPageWrapper class="front-cover" data-density="hard">
					<img :src="e.bookImgs.t[0]" />
				</BookPageWrapper>
				<BookPageWrapper>
					<div class="guarda" :style="{ backgroundImage: `url('${normalizeSrc(e.config.guardaTapaImg)}')` }"></div>
				</BookPageWrapper>
				<BookPageWrapper :key="i" v-for="(src, i) of e.bookImgs.i">
					<img :src="src" />
				</BookPageWrapper>
				<BookPageWrapper>
					<div class="guarda" :style="{ backgroundImage: `url('${normalizeSrc(e.config.guardaContratapaImg)}')` }">
					</div>
				</BookPageWrapper>
				<BookPageWrapper class="back-cover" data-density="hard">
					<img :src="e.bookImgs.t[0]" />
				</BookPageWrapper>
				<BookPageWrapper class="pre-cover d-flex align-center justify-center fill-height flex-column">
					<Button color="darkblue" @click="backToStartFlip" large class="px-8">
						<v-icon class="mr-2">mdi-restart</v-icon> {{ 'Volver a empezar' | lang }}
					</Button>
				</BookPageWrapper>
			</div>
		</div>
		<div class="page-count" v-if="showCounter">
			<b>{{ currentPage }}</b> / <b>{{ totalPages }}</b>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.book-container {
	position: relative;
	margin: auto;
	padding-bottom: 32px;
	overflow: hidden;
}

.book-parent {
	opacity: 0;
	transition: opacity 0.2s;
	margin: auto;

	&--ready {
		opacity: 1;
	}

	&--unready {
		height: 0 !important;
		position: absolute;
	}
}

.book {
	width: 100%;
}

.page-wrapper {
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);

	&.pre-cover {
		box-shadow: none;
	}

	.guarda {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		height: 100%;
	}
}

.cover-txt-anim-container {
	animation: rotation 12s linear infinite;
	transform-origin: center;
}

.cover-txt-anim {
	animation: pulse 5s linear infinite;
	transform-origin: center;
}

.cover-cta {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(-360deg);
	}
}

@keyframes pulse {

	0%,
	100% {
		transform: scale(1);
	}

	50% {
		transform: scale(0.85);
	}
}

.page-count {
	bottom: 0;
	position: absolute;
	width: 100%;
	text-align: right;
	padding-right: 20px;
}
</style>
