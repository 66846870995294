<script>
export default {
	lang: 'books',
	inject: ['editor'],
	computed: {
		e() {
			return this.editor()
		},
	},
	mounted() {
		this.$assignDefaults(this.e.customization.data, { gender: null })
	},
}
</script>

<template>
	<div>
		<v-item-group
			v-model="e.customization.data.gender"
			mandatory
			class="d-flex align-center justify-center"
		>
			<v-item v-slot="{ active, toggle }" value="m">
				<div class="gender-selector" :class="{ active }" @click="toggle">
					<div class="gender-selector__icon">
						<Media :src="e.config.maleCharacterImg" max-width="80" eager />
					</div>
					<div class="gender-selector__label">
						{{ 'Él' | lang }}
					</div>
				</div>
			</v-item>
			<v-item v-slot="{ active, toggle }" value="f">
				<div class="gender-selector" :class="{ active }" @click="toggle">
					<div class="gender-selector__icon">
						<Media :src="e.config.femaleCharacterImg" max-width="80" eager />
					</div>
					<div class="gender-selector__label">
						{{ 'Ella' | lang }}
					</div>
				</div>
			</v-item>
		</v-item-group>
	</div>
</template>

<style lang="scss" scoped>
.gender-selector {
	cursor: pointer;
	padding: 4px;
	opacity: 0.7;
	transition: all 0.3s;
	text-align: center;
	.gender-selector__icon {
		transition: all 0.3s;
		border: 4px solid transparent;
		border-radius: 100%;
		padding: 4px;
		overflow: hidden;
	}
	&:not(.active):hover {
		opacity: 0.8;
		.gender-selector__icon {
			border: 4px solid var(--v-blue-lighten3);
		}
	}
	&.active {
		opacity: 1;
		.gender-selector__icon {
			border: 4px solid var(--v-blue-base);
		}
	}
	.gender-selector__label {
		padding-top: 4px;
		font-weight: bold;
	}
}
</style>
