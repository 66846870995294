import { render, staticRenderFns } from "./comp_BookEditorCustomizationInputs-astralito-adultos.vue?vue&type=template&id=0fa5a175&scoped=true&"
import script from "./comp_BookEditorCustomizationInputs-astralito-adultos.vue?vue&type=script&lang=js&"
export * from "./comp_BookEditorCustomizationInputs-astralito-adultos.vue?vue&type=script&lang=js&"
import style0 from "./comp_BookEditorCustomizationInputs-astralito-adultos.vue?vue&type=style&index=0&id=0fa5a175&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fa5a175",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VMenu})
